<template>
  <b-modal class="bmodal" size="sm" id="addMeetingLocationModal" ref="modalAddMeetingLocation"
    :title="i18n.components && i18n.components['add-meeting-location'].tcAddLocation" centered
    @ok="addNewMeetingAddress($event)" @cancel="handleCancelClick()" @show="handleShow()" :ok-title="i18n.tcOk"
    :cancel-title="i18n.tcCancel" :ok-disabled="!validData">
    <div>
      <form ref="form">
        <b-container fluid class="text-light text-left">
          <div class="pt-5">
            <b-form>
              <b-form-group class="form-element">
                <b-form-input id="name-input" :placeholder="i18n.tcName" v-model="form.name"
                  :state="form.name !== null && form.name !== ''" required></b-form-input>
              </b-form-group>
              <b-form-group class="form-element">
                <b-form-input id="address-1-input" :placeholder="i18n.tcAddress1" v-model="form.address_1"
                  :state="form.address_1 !== null && form.address_1 !== ''" required></b-form-input>
              </b-form-group>
              <b-form-group class="form-element">
                <b-form-input id="address-2-input" :placeholder="i18n.tcAddress2" v-model="form.address_2" />
              </b-form-group>
              <b-form-group class="form-element">
                <b-form-input class="mb-2 mr-sm-2 mb-sm-0" id="city-input" :placeholder="i18n.tcCity"
                  v-model="form.city" :state="form.city !== null && form.city !== ''" required></b-form-input>
              </b-form-group>
              <b-form-group class="form-element">
                <b-form-input id="state-input" v-if="!isUSSelected" :placeholder="i18n.tcStateProvince"
                  v-model="form.state" class="mb-2 mr-sm-2 mb-sm-0" type="text" maxlength="40"></b-form-input>
                <b-form-select class="form-control g-select mb-2 mr-sm-2 mb-sm-0s" v-if="isUSSelected"
                  v-model="form.state" :options="optionsStates" :state="form.state !== null && form.state !== ''"
                  :required="true">
                  <template slot="first"></template>
                </b-form-select>
              </b-form-group>
              <b-form-group class="form-element">
                <b-form-input class="mb-2 mr-sm-2 mb-sm-0" id="zip-input" :placeholder="i18n.tcZip" v-model="form.zip"
                  :state="!isUSSelected || (form.zip !== null && form.zip !== '')"
                  :required="isUSSelected ? true : false"></b-form-input>
              </b-form-group>
              <b-form-group class="form-element">
                <b-form-select v-model="form.cnt_key" :options="optionsCountries"
                  class="form-control g-select flex-0 mr-3 mb-3">
                  <template slot="first"></template>
                </b-form-select>
              </b-form-group>
            </b-form>
          </div>
        </b-container>
      </form>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from 'vuex'
import calendarService from '../services/calendarService'
import constantData from '@/json/data.json'
import { Loader } from '@/mixins/Loader'

export default {
  name: 'add-meeting-location',
  mixins: [Loader],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          components: {
            'add-meeting-location': {
              tcAddLocation: 'Add Location',
              tcCancel: 'Cancel',
              tcOk: 'Ok',
              tcOperationWasSuccessful: 'Operation was successful',
            },
          },
          tcAddress1: 'Address 1',
          tcAddress2: 'Address 2',
          tcCity: 'City',
          tcName: 'Name',
          tcStateProvince: 'State/Province',
          tcZip: 'Zip',
        }
      },
    },
  },
  data() {
    return {
      country_us: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
      form: {
        state: null,
        zip: null,
        address_1: null,
        address_2: null,
        city: null,
        name: null,
        country: '',
        cnt_key: null,
      },
      isDisabled: true,
      modalTitle: 'Add Location',
      originalData: null,
      result: {},
      state: null,
    }
  },
  methods: {
    ...mapActions({
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      getCountryStates: 'search/getStatesForCountry',
    }),
    async addNewMeetingAddress() {
      if (this.validData) {
        let postAddress = {
          adr_line1: this.form.address_1,
          adr_line2: this.form.address_2,
          adr_city: this.form.city,
          adr_state: null,
          adr_postal_code: this.form.zip,
          adr_state: this.form.state,
          adr_cnt_key: this.form.cnt_key,
          adr_nag_key: null,
          adr_user: this.user.demograph.member_number,
          adr_business_name: this.form.name,
          org_key: null,
        }
        this.result = {}
        this.showLoader()
        await calendarService
          .UpsertAddress(postAddress)
          .then((res) => {
            if (res.status === 200) {
              this.$emit('locationSelectionMade', { key: res.data, name: postAddress.adr_business_name })
              this.message(
                this.i18n.components['add-meeting-location'].tcOperationWasSuccessful,
                'success',
                this.i18n.tcOk
              )
              this.closeLoader()
            }
          })
          .catch((err) => {
            this.message()
            this.closeLoader()
          })
      } else {
        return
      }
    },
    handleCancelClick() {
      this.clearForm()
    },
    async handleShow() {
      this.clearForm()
      this.form.cnt_key = this.userCountryObject.key
      this.form.country = this.userCountryObject.name
      await Promise.all([this.getCountryStates(this.USA_country)])
    },
    closeModal() {
      this.clearForm()
      this.$refs.modalAddMeetingLocation.hide()
    },
    clearForm() {
      this.form.name = null
      this.form.address_1 = null
      this.form.address_2 = null
      this.form.city = null
      this.form.state = null
      this.form.zip = null
      this.form.cnt_key = this.userCountryObject.key
    },
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      countryStates: 'search/countryStateSelect',
      prefCulture: 'user/userPreferredCulture',
      userCountryObject: 'user/userCountryObject',
    }),
    isUSSelected() {
      return this.form.cnt_key === null || this.form.cnt_key.toLowerCase() === this.USA_country
    },
    // FormOptions
    optionsCountries() {
      return [
        {
          value: null,
          text: this.i18n.tcSelectACountry || 'Select a country',
          disabled: true,
        },
        ...this.convertedAddressFormOptions.countries,
      ]
    },
    optionsStates() {
      return [
        {
          value: null,
          text: this.i18n.tcSelectAState || 'Select a state',
          disabled: true,
        },
        ...this.countryStates,
      ]
    },
    // END FormOptions
    USA_country() {
      return constantData.USA_country
    },
    validData() {
      return (
        this.form.name &&
        this.form.name.length > 0 &&
        this.form.address_1 &&
        this.form.address_1.length > 0 &&
        this.form.city &&
        this.form.city.length > 0 &&
        ((this.form.state && this.form.state.length > 0) || !this.isUSSelected) &&
        ((this.form.zip && this.form.zip.length > 0) || !this.isUSSelected)
      )
    },
  },
  watch: {
    form: {
      handler(netval, oldva) {
        this.isDisabled = Object.keys(this.form).some((item) => {
          return this.form[item] === null || this.form[item] === ''
        })
      },
    },
  },
  async created() {
    await Promise.all([this.loadAddressFormOptions()])
  },
}
</script>

<style>

</style>
